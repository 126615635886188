import moment from "moment";

export class ScreenTimer {
  private endTime: Date = new Date();

  constructor(
    public fontSize1: number,
    public fontSize2: number,
    public fontFamily: string,
    public backgroundColor: string,
    public minutes: number,
    public startText: string,
    public startNowText: string,
    public text: string,
    public playSound: boolean,
    public image: string,
    public color: string,
    public sound: string,
    public time: string,
    public round: boolean,
    public roundTo: number
  ) {
    this.setMinutes(minutes);
    setInterval(() => this.showTime(), 10000);
    this.update();
  }

  setText(text: string, minutes: number = 0) {
    $("#container").removeClass("animated");
    if (minutes !== 0) this.setMinutes(minutes);
    this.text = text;
    this.update();
  }

  setTime(text: string, time: Date) {
    this.endTime = moment(time).toDate();
    this.text = text;
    this.update();
  }

  setMinutes(minutes: number) {
    let minutesCalc = moment().add(minutes, "minutes").minutes();
    if (this.round) {
      while (minutesCalc % this.roundTo !== 0) {
        minutesCalc = moment()
          .add(++minutes, "minutes")
          .minutes();
      }
    }
    this.minutes = minutes;
    this.endTime = moment().add(this.minutes, "minutes").toDate();
    this.update();
  }

  update() {
    $(".content1")
      .text(this.text)
      .css("font-size", this.fontSize1)
      .css("font-family", this.fontFamily);
    $(".content1, .content2").css("color", this.color);
    $("#soundSource").attr("src", this.sound);
    if (this.image !== "") {
      $("body").css("background-color", "");
      $("body").attr(
        "style",
        "background: url(" +
          this.image +
          ") no-repeat center center fixed; background-size: cover"
      );
    } else {
      $("body").removeAttr("style");
      $("body").css("background-color", this.backgroundColor);
    }
    this.showTime();
  }

  stop() {
    $("audio").trigger("stop");
    $("#container").removeClass("animated");
  }

  showTime() {
    const rt = moment(this.endTime).add(30, "second").diff(moment(), "minutes");
    document.title = "OTT @ " + rt;
    let fs = "";
    if (rt <= 0) {
      if (this.playSound) $("audio").trigger("play");
      $("#container").addClass("animated infinite pulse");
      fs = this.startNowText;
    } else {
      fs = this.startText
        .replace("{{time}}", moment(this.endTime).format("HH:mm"))
        .replace("{{minutes}}", rt > 0 ? rt.toString() : "*");
    }
    $(".content2")
      .text(fs)
      .css("font-size", this.fontSize2)
      .css("font-family", this.fontFamily);
  }
}

export let defaults = {
  en: {
    startText: "We start at {{time}} (in {{minutes}} min.)",
    minutes: 10,
    text: "Text missing",
    startNowText: "We will start again in just a minute!",
    backgroundColor: "lightblue",
    image: "",
    color: "black",
    sound: "beep.mp3",
    round: true,
    playSound: false,
    roundTo: 5,
  },
  da: {
    startText: "Vi starter kl. {{time}} ({{minutes}} min.)",
    minutes: 10,
    text: "Tekst mangler",
    startNowText: "Vi starter om et øjeblik!",
    backgroundColor: "lightblue",
    image: "",
    color: "black",
    sound: "beep.mp3",
    playSound: false,
    round: true,
    roundTo: 5,
  },
  images: [
    {
      name: "Coffee break",
      src: "https://cdn.pixabay.com/photo/2016/03/26/13/09/workspace-1280538_960_720.jpg",
    },
    {
      name: "Assignment",
      src: "https://cdn.pixabay.com/photo/2016/11/30/20/44/computer-1873831_960_720.png",
    },
    {
      name: "Universe",
      src: "https://cdn.pixabay.com/photo/2021/09/26/14/37/milky-way-6657951_960_720.jpg",
    },
  ],
};

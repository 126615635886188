import jquery from "jquery";
import moment from "moment";
import { defaults } from "./defaults";
import { ScreenTimer } from "./screentimer";

// @ts-ignore
window.$ = window.jQuery = jquery;

$("#cancel").click(function () {
  $(".modal").modal("hide");
});

$(".content1, .content2").click(function () {
  $("audio").trigger("stop");
  ott.playSound = false;

  $("#l1").val(ott.text);
  $("#l2").val(ott.startText);
  $("#l3").val(ott.startNowText);
  $("#tid").val(ott.time);
  $("#image").val(ott.image);
  $("#soundSource").attr("src", ott.sound);
  $("#color").val(ott.color);
  $("#backgroundcolor").val(ott.backgroundColor);
  $("#lyd").prop("checked", ott.playSound);
  $("#round").prop("checked", ott.round);
  $("#l1").select();
  $(".modal").modal("setting", "closable", false).modal("show");
});

$("#defaultEn").click(function () {
  ott.startNowText = defaults.en.startNowText;
  ott.text = defaults.en.text;
  ott.startText = defaults.en.startText;
  ott.backgroundColor = defaults.en.backgroundColor;
  ott.minutes = defaults.en.minutes;
  ott.color = defaults.en.color;
  ott.image = defaults.en.image;
  ott.sound = defaults.en.sound;
  ott.round = defaults.en.round;
  ott.roundTo = defaults.en.roundTo;
  $(".content1, .content2").trigger("click");
});

$("#defaultDa").click(function () {
  ott.startNowText = defaults.da.startNowText;
  ott.text = defaults.da.text;
  ott.startText = defaults.da.startText;
  ott.backgroundColor = defaults.da.backgroundColor;
  ott.minutes = defaults.da.minutes;
  ott.color = defaults.da.color;
  ott.image = defaults.da.image;
  ott.sound = defaults.da.sound;
  ott.round = defaults.da.round;
  ott.roundTo = defaults.da.roundTo;
  $(".content1, .content2").trigger("click");
});

$("#link").click(function () {
  let l = "?";
  l += "lang=da";
  l += "&text=" + ott.text;
  l += "&startText=" + ott.startText;
  l += "&startNowText=" + ott.startNowText;
  l += "&image=" + ott.image;
  l += "&color=" + ott.color;
  l += "&backgroundColor=" + ott.backgroundColor;
  l += "&minutes=" + ott.minutes;
  l += "&playSound=" + ott.playSound;
  window.location.href =
    "http://" + window.location.host + window.location.pathname + l;
});

$("#start").click(function () {
  ott.stop();
  $(".modal").modal("setting", "closable", false).modal("hide");
  ott.color = $("#color").val()!.toString();
  if ($("#image").val()!.toString() != "") {
    ott.image = $("#image").val()!.toString();
  } else {
    ott.image = "";
  }

  ott.round = $("#round").prop("checked");
  // if ($("#sound").val()!.toString() != "") {
  //   $("#lyd").prop("checked", true);
  // } else {
  //   $("#lyd").prop("checked", false);
  // }
  ott.playSound = $("#lyd").prop("checked");
  ott.time = $("#tid").val()!.toString();
  ott.backgroundColor = $("#backgroundcolor").val()!.toString();
  const t = $("#tid").val()!.toString();
  if (t.indexOf(":", 0) > -1) {
    const h = t.substring(0, t.indexOf(":", 0));
    const m = t.substring(t.indexOf(":", 0) + 1);
    ott.setTime(
      $("#l1").val()!.toString(),
      moment().minute(parseInt(m, 10)).hour(parseInt(h, 10)).toDate()
    );
  } else {
    ott.setText($("#l1").val()!.toString(), parseInt(t, 10));
  }
  // let newAudio = $(createAudio(ott.sound));
  // $("#audio").replaceWith(newAudio);
});

$(document).on("keypress", function (e) {
  if (e.key === "Enter") {
    if ($(".modal").is(":visible")) {
      $("#start").trigger("click");
    }
  }
});

document.addEventListener("keydown", function (event) {
  if (event.key === "Escape") {
    if ($(".modal").is(":visible")) {
      $("#cancel").trigger("click");
    }
  }
});

// function createAudio(src: string) {
//   let output =
//     '<audio id="audio" controls preload="none" style="display: block">';
//   output += "<source id='soundSource' src='" + src + '" type="audio/mp3" />';
//   output += "</audio>";
//   return output;
// }

function getQueryString(key: string) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
}

let ott: ScreenTimer;
$(document).ready(function () {
  let lang = getQueryString("lang") == null ? "en" : getQueryString("lang");
  let fontSize1 = 82;
  let fontSize2 = 52;
  let fontFamily = "Oxygen";
  let backgroundColor =
    lang == "en" ? defaults.en.backgroundColor : defaults.da.backgroundColor;
  let minutes = 10;
  let startText = lang == "en" ? defaults.en.startText : defaults.da.startText;
  let startNowText =
    lang == "en" ? defaults.en.startNowText : defaults.da.startNowText;
  let text: string = lang == "en" ? defaults.en.text : defaults.da.text;
  let playSound = lang == "en" ? defaults.en.playSound : defaults.da.playSound;
  let image = lang == "en" ? defaults.en.image : defaults.da.image;
  let color = lang == "en" ? defaults.en.color : defaults.da.color;
  let sound = lang == "en" ? defaults.en.sound : defaults.da.sound;
  let round = lang == "en" ? defaults.en.round : defaults.da.round;
  let roundTo = lang == "en" ? defaults.en.roundTo : defaults.da.roundTo;

  text = getQueryString("text") != null ? getQueryString("text") + "" : text;
  startText =
    getQueryString("startText") != null
      ? getQueryString("startText") + ""
      : startText;
  startNowText =
    getQueryString("startNowText") != null
      ? getQueryString("startNowText") + ""
      : startNowText;
  playSound = getQueryString("playSound") != null ? true : false;
  image =
    getQueryString("image") != null ? getQueryString("image") + "" : image;
  color =
    getQueryString("color") != null ? getQueryString("color") + "" : color;
  backgroundColor =
    getQueryString("backgroundColor") != null
      ? getQueryString("backgroundColor") + ""
      : backgroundColor;
  minutes =
    getQueryString("minutes") != null
      ? parseInt(getQueryString("minutes") + "")
      : minutes;

  let time = minutes.toString();

  ott = new ScreenTimer(
    fontSize1,
    fontSize2,
    fontFamily,
    backgroundColor,
    minutes,
    startText,
    startNowText,
    text,
    playSound,
    image,
    color,
    sound,
    time,
    round,
    roundTo
  );

  $("#imageDiv").dropdown({
    allowAdditions: true,
    onChange: function (v, t) {
      if (v != "") $("#color").val("white");
    },
  });
  $("#soundDiv").dropdown({
    allowAdditions: true,
    onChange: function (v, t) {
      if (v != "") $("#lyd").prop("checked", true);
    },
  });
  $("audio").trigger("load");
  $("#sticky1").show();
  defaults.images.forEach((v) => {
    let t = '<div class="item" data-value="' + v.src + '">' + v.name + "</div>";
    $("#menuImage").append(t);
  });
});
